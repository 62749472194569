export class BudgetPool {
    public static values = [
       "MortSci",
       "GF OCAP",
       "SLP/AUD",
       "CLIA",
       "Hospice",
       "SLP",
       "BA",
       "Federal Pool",
       "AUD",
       "HOP Pool",
       "HF State Pool",
       "INTRP",
       "HID",
       "MOR",
       "NAR",
       "Doula",
       "N/A",
       "Case Mix"
    ];
}