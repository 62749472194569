export class SurveyType {

    public surveyTypeId?: string;
    public surveyTypeDesc?: string;
    public surveyType?: string;
    public commonProjectFlag?: string;
    public surveyStatus?: string;
    public expReportFlag?: string;
    public surveyClassification?: string;
    public budgetPool?: string;
    public nfIcfmrFlag ?: string;
    insertUid?: any;
    insertDate?: any;
    modifyUid?: any;
    modifyDate?: any;


}